import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from './../../../config';
import Logo from './../../../components/Logo';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = ({ disabled }) => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath} disabled={disabled}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
