export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    CONTRACT: '/contracts',
    CAR: '/cars',
    MEMBER: '/members',
    FORGET_PASSWORD: '/forget_password',
    RESET_PASSWORD: '/reset_password',
    CHANGE_PASSWORD: '/change_password',
    SET_PASSWORD: '/set_password',
    NOTIFICATION: '/notification',
    PROFILE: '/profile',
    CONTRACT_DETAIL: '/contracts/:id',
    EDIT_PROFILE: '/edit_profile',
    TODAY_PRICE: '/today_price'
};

export const paths = [
    ROUTES.LOGIN,
    ROUTES.CONTRACT,
    ROUTES.CAR,
    ROUTES.MEMBER,
    ROUTES.FORGET_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.CHANGE_PASSWORD,
    ROUTES.SET_PASSWORD,
    ROUTES.NOTIFICATION,
    ROUTES.PROFILE,
    ROUTES.CONTRACT_DETAIL,
    ROUTES.TODAY_PRICE
    // ROUTES.EDIT_PROFILE
];

export const publicRoutes = [
    ROUTES.LOGIN,
    ROUTES.FORGET_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.SET_PASSWORD,
    ROUTES.NOTIFICATION,
    ROUTES.TODAY_PRICE
];
